<template lang="pug">
transition(name="modal")
  div
    .modal-mask(v-if="visible")
      .modal-wrapper
        .modal-dialog(role="document")
          .modal-content
            .modal-header
              .h5.font-weight-bold.card-title.px-3.matador-text-01 {{ title }} Device
              button.close(
                type="button",
                data-dismiss="modal",
                aria-label="Close",
                @click="onClose()"
              )
                span.pr-3(aria-hidden="true", @click="showAdd = false") &times;
            .modal-body
              form.px-3.mt-3
                .form-group
                  label UUID/IMEI
                  input.form-control(type='text' placeholder='Insert UUID/IMEI (9167346195465986)', 
                  @change="changeImei", @input="changeImei", :value="inpImei"
                  )
                .form-group
                  label(for='username') Nama Perangkat
                  input#username.form-control(type='text' placeholder="Masukan Nama Perangkat", v-model="description")
                .form-group
                  label Operator Jaringan / Operator
                  .row
                    .col
                      select.custom-select.form-control(type='text',v-model="network_provider"
                      :class="{ 'matador-placeholder': network_provider === ''}" style="color:black !important")
                        option(value="" selected disabled) Pilih Operator Jaringan
                        option(v-for="item in networkList" :value="item.name") {{item.name}}
                    .col-auto.pl-0
                      matador-button(iconOnly, size="sm", @click="showOperatorModal=true")
                        .h3.m-0
                          plus(:size="64")
                .form-group
                  label Nomor SIM
                  input.form-control(type='text' placeholder="Insert SIM Number",
                  @change="changePhone", @input="changePhone", :value="inpSim"
                  )
                div.float-right.mt-3
                  button.btn.btn-link.mr-3(type="button" @click="onClose()") Batalkan
                  matador-button(:disabled="!isValid" @click="doSave") {{ buttonTitle }}
      confirmation-modal(
        v-if="showCancelModal"
        @close="showCancelModal = false" 
        @confirm="doClose()" 
        title="Buang Perubahan"
        confirm-text="Ya, Buang"
      )
        p.m-0
          | Apakah Anda yakin ingin membuang perubahan yang Anda buat?
    operator-modal(v-if="showOperatorModal" @close="showOperatorModal = false" @success="onAddOperator")
    confirmation-modal(
      v-if="showDeleteModal"
      @close="showDeleteModal = false" 
      @confirm="onConfirmDelete" 
      title="Hapus Perangkat"
      confirm-text="Ya, Hapus"
    )
      p.m-0
        | Apakah Anda ingin menghapus perangkat ini?
</template>

<script>
import * as yup from "yup";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import OperatorModal from "./OperatorModal.vue";
import {
  getNetworkOperatorList,
  postAddDevice,
  postEditDevice,
  postRemoveDevice,
} from "@/utils/api";
import { current } from "immer";

let deviceSchema = yup.object().shape({
  imei: yup.string().required(),
  sim_number: yup.string().required(),
  network_provider: yup.string().required(),
  description: yup.string().required(),
});

export default {
  data() {
    return {
      created_by: localStorage.user_id,
      device_id: "",
      imei: "",
      sim_number: "",
      network_provider: "",
      description: "",
      networkList: [],
      showOperatorModal: false,
      showDeleteModal: false,
      showCancelModal: false,
      loading: false,
    };
  },
  watch: {
    currentDevice: function (newVal) {
      if (newVal) {
        this.device_id = newVal.device_id;
        this.imei = newVal.imei || "";
        this.sim_number = newVal.sim_number;
        this.network_provider = newVal.network_provider || "";
        this.description = newVal.description;
      }
    },
    auth: {
      immediate: true,
      handler: function (newVal) {
        // console.log({ newVal });
      },
    },
  },
  components: {
    Plus,
    EyeOutline,
    EyeOffOutline,
    OperatorModal,
    ConfirmationModal,
  },
  props: ["close", "currentDevice", "onSave", "onDelete", "visible"],
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    isDirty() {
      if (this.currentDevice && this.currentDevice.device_id) {
        return (
          this.currentDevice.imei != this.imei ||
          this.currentDevice.sim_number != this.sim_number ||
          this.currentDevice.network_provider != this.network_provider ||
          this.currentDevice.description != this.description
        );
      }
      return (
        this.imei ||
        this.sim_number ||
        this.network_provider ||
        this.description
      );
    },
    isValid() {
      return deviceSchema.isValidSync(this.inputData);
    },
    inputData() {
      let obj = {
        created_by: this.created_by,
        device_id: this.device_id,
        imei: this.imei,
        sim_number: this.sim_number,
        network_provider: this.network_provider,
        description: this.description,
      };
      return obj;
    },
    title() {
      return !!this.device_id ? "Ubah" : "Tambahkan";
    },
    buttonTitle() {
      return !!this.device_id ? "Ubah" : "Tambahkan";
    },
    inpSim() {
      return this.sim_number;
    },
    inpImei() {
      return this.imei;
    },
  },
  methods: {
    changeImei(event) {
      let val = event.target.value.trim();
      // It can only be positive integer or empty, and the rule can be modified according to the requirement.
      if (/^[0-9]\d*$|^$/.test(val)) {
        this.imei = val || "";
      } else {
        event.target.value = this.imei || "";
      }
    },
    changePhone(event) {
      let val = event.target.value.trim();
      // It can only be positive integer or empty, and the rule can be modified according to the requirement.
      if (/^[0-9]\d*$|^$/.test(val)) {
        this.sim_number = val || "";
      } else {
        event.target.value = this.sim_number || "";
      }
    },
    onClose() {
      if (this.isDirty) {
        this.showCancelModal = true;
      } else {
        this.doClose();
      }
    },
    onAddOperator(newVal) {
      this.networkList = [{ name: newVal }, ...this.networkList];
      this.network_provider = newVal;
    },
    doClose() {
      this.showCancelModal = false;
      this.$emit("close");
    },
    delete() {
      this.showDeleteModal = true;
    },
    doSave() {
      this.loading = true;
      const callback = !!this.currentDevice ? postEditDevice : postAddDevice;
      callback({
        ...this.inputData,
        organization_id: this.auth.organization_id,
      }).then(({ data: { result, code, message } }) => {
        this.loading = false;
        if (code === 200) {
          this.$emit("onSave", result);
        } else {
          this.$toast.error(message);
        }
      });
    },
    onConfirmDelete() {
      postRemoveDevice(this.inputData).then(
        ({ data: { result, code, message } }) => {
          this.loading = false;
          this.showDeleteModal = false;
          if (code === 200) {
            this.$emit("onDelete", result);
          } else {
            this.$toast.error(message);
          }
        }
      );
    },
    fetchNetworkList() {
      getNetworkOperatorList().then(({ data: { code, result, message } }) => {
        this.networkList = result;
      });
    },
  },
  mounted() {
    this.fetchNetworkList();
  },
};
</script>
