import { render, staticRenderFns } from "./AlertPage.vue?vue&type=template&id=439173fd&lang=pug"
import script from "./AlertPage.vue?vue&type=script&lang=js"
export * from "./AlertPage.vue?vue&type=script&lang=js"
import style0 from "./AlertPage.vue?vue&type=style&index=0&id=439173fd&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports